import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import '../../CSS/SelectDate.css';

const reviews = [
  { name: 'Sofia', stars: 5, review: 'Increible, es un genio!' },
  { name: 'Ayelen', stars: 4, review: 'Me soprendio que lindo es, solo que no le gusta el asado' },
  { name: 'Carlos', stars: 5, review: 'No soy gay pero me chamuyo' },
  { name: 'Danielle', stars: 5, review: 'Muy buen experencia, me trato como princessa' },
  { name: 'Mama de Nick', stars: 5, review: 'Mi hijo es muy lindo' },
  { name: 'Fiona', stars: 4, review: 'Muy buen hombre, casi perfecto, lo recomiendo' },
  { name: 'Sol', stars: 5, review: 'No sabia que hombres tan bellos existian en este mundo' },
  { name: 'Marti', stars: 3, review: 'Era ok, un poco racista' },
  { name: 'Luz', stars: 1, review: 'Horrible, nunca me llamo bonita' },
  { name: 'Jenny', stars: 4, review: 'Un chico para presentar a tus papas' },
];

const SelectDate = () => {
  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [dateType, setDateType] = useState('');
  const [additionalQuestions, setAdditionalQuestions] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [instagram, setInstagram] = useState('');

  useEffect(() => {
    emailjs.init('ROBND5jhnBWzpofFn'); // Initialize EmailJS with your Public Key
  }, []);

  const sendInvite = (e) => {
    e.preventDefault();

    const templateParams = {
      name: name,
      date: date,
      time: time,
      dateType: dateType,
      additionalQuestions: additionalQuestions,
      userEmail: userEmail,
      instagram: instagram,
      to_email: userEmail,
      confirmation_email: 'nradstake@yahoo.com'
    };

    emailjs.send('service_mz2dyzw', 'template_pek52et', templateParams)
      .then((response) => {
        alert('Invite sent successfully!');
      }, (error) => {
        alert('Failed to send invite. Please try again.');
      });
  };

  return (
    <div className="select-date-app">
      <h2>Select Date and Time</h2>
      <p>Estoy disponible generalmente después de las 18:00 durante los días de semana y debería estar libre la mayoría de los fines de semana.</p>
      <form onSubmit={sendInvite}>
        <div>
          <label>Nombre: </label>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
        </div>
        <div>
          <label>Fecha: </label>
          <input type="date" value={date} onChange={(e) => setDate(e.target.value)} required />
        </div>
        <div>
          <label>Hora: </label>
          <input type="time" value={time} onChange={(e) => setTime(e.target.value)} required />
        </div>
        <div>
          <label>Que Tipo de Cita??: </label>
          <input type="text" value={dateType} onChange={(e) => setDateType(e.target.value)} required />
        </div>
        <div>
          <label>Preguntas Addicionales?: </label>
          <textarea value={additionalQuestions} onChange={(e) => setAdditionalQuestions(e.target.value)} />
        </div>
        <div>
          <label>Tu Email: </label>
          <input type="email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} required />
        </div>
        <div>
          <label>Tu Instagram: </label>
          <input type="text" value={instagram} onChange={(e) => setInstagram(e.target.value)} />
        </div>
        <button type="submit">Manda Invite</button>
      </form>

      <div className="review-section">
        <h3>Reviews</h3>
        <div className="review-marquee">
          <div className="review-marquee-inner">
            {/* Original set of reviews */}
            {reviews.map((review, index) => (
              <div key={index} className="review-item">
                <div className="review-stars">
                  {'★'.repeat(review.stars) + '☆'.repeat(5 - review.stars)}
                </div>
                <p><strong>{review.name}</strong>: {review.review}</p>
              </div>
            ))}
            {/* Duplicate set of reviews for seamless scrolling */}
            {reviews.map((review, index) => (
              <div key={`duplicate-${index}`} className="review-item">
                <div className="review-stars">
                  {'★'.repeat(review.stars) + '☆'.repeat(5 - review.stars)}
                </div>
                <p><strong>{review.name}</strong>: {review.review}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectDate;
